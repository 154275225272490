:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1200px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1200px;
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: Hind, sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Roboto.css";
@import "/fontmodules/RobotoCondensed.css";
@import "/fontmodules/DroidSerif.css";
@import "/cssmodules/normalize.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/



/*----------------------------------- General --------------------------------------*/

img {
    border: 0;
    max-width: 100%;
    height: auto;
}


.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}



body {
    background: #EEEEEE;
    font-family: "RobotoCondensed", san-serif;
    /* font-weight's att välja mellan är 100,300,400,500,700,900 */
}


header {
    background: rgba(29, 165, 156, 0.8);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
}


.hemisdaochdesignav  {
    background: rgba(40, 142, 135, 0.8);
    padding: 8px 20px 8px 8px;

}

.hemisdaochdesignav p, .hemisdaochdesignav a {
    font-family: "Roboto", sans-serif;
    color:#FFFFFF;
    font-size: 0.8rem;
    padding:0px;
    margin:0px;
}


/*----------------------------------- Logotype --------------------------------------*/

.logo a {
       padding: 15px 0px 5px 15px;
}/*Add width:100% för att ge egen "rad"*/


.logo img {
    height: 6vh;
    max-height: 6vh;
    width: auto;
}


/* ---------------------------------- Rubrikerna h1 h2 h3 h4 h5 h6 --------------------------- */

h1 {
    color: #666;
    font-size: 2.25rem;
    font-weight: 300;
    letter-spacing: 1px;
}

h2 {
    color: #666666;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h3 {
    color: #666666;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h4 {
    color: #666666;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h5 {
    color: #666666;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h6 {
    color: #666666;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 2px;

}

p  {

    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.2em;
    font-weight: 300;
    line-height: 150%;
    
}


/*-------------------------------------------------------------- Bildspel ------------------------------------------------------------------------*/

.slick-initialized .slick-slide{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.slick-slider {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    top:0;
    z-index: -10; /* z-index - sätter vilken "nivå" bildspelet / "vad man vill" ska hamna på */
}

.slick-slide {
    height: 100vh;
    width:100%;
    background-size: cover;
    background-position: top center ;
}

.slideshow_titel {
    font-family: 'Roboto',arial,san-serif;
    background: rgba(51, 51, 51, 0.25);
    font-weight: 100;
    font-size: 2.8em;
    position: relative;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 50vh;
    margin-bottom: 0;
    z-index: -8;
    width: 100%;
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-left: 0;
    border-right: 0;
    border-color: #fff;
}

/*-------------------------------------------------------------- Topimage / Toppbild ------------------------------------------------------------------------*/
.topimage {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    height:100vh;
    top:0;
    z-index: -10; 
    background-size: cover;
    background-position: top center;
}


/*-------------------------------------------------------------- Pagecontent / Innehåll sida och startsida ------------------------------------------------------------------------*/

.pagecontent {
    margin-top:70vh;
    position: relative;
    z-index: 15;
    background: #ececec;
}

.bodycol {
    margin-top:0vh;
    padding-top:0vh;
}

.bodycol img {
    margin-top:3vh;
    margin-bottom:7px;
}

.pagetitle h1 {
    margin-bottom:0vh;
    padding-bottom:0vh;
}
.pagetitle {
    margin-bottom:0vh;
    padding-bottom:0vh;
}

.pdfloop  {
    color:#487d92;
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 150%;
    
}

.pdfloop a {
    color:#487d92;
}

.pdfloop svg {
    fill: #487d92;
}


.margin>* {
margin-top:0px;
}

.kontaktlankboxbild {
    min-height: 250px;
    max-height: 250px;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.kontaktlankboxbild{
    display:block;
}

/*---------- Gallery galleri galleries --------------*/

.gallerybackground {
background: rgb(255,255,255);
}

.imagelist_link {
    min-height: 500px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.imagelist_link{
    display:block;
}


/*----------------------------------- Länkarna --------------------------------------*/

a {
    text-decoration: none;
}

a:active, a:hover {
    text-decoration: underline;
}

/*----------------------------------- Meny / Menu --------------------------------------*/



nav li{
    background: none;
}

nav {
    font-size:0.9em;
    color:rgb(255, 255, 255);
    float:right;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.1em;
}

.menu li a,
.menu .dropdown ul
{
    color:rgb(255, 255, 255);
    text-decoration: none;
}

.menu li {
     /* osynlig / transparent för att ta samma utrymme som active*/
    border-bottom: 1px solid rgba(255, 255, 255, 0.0);
}
.menu>li {
    margin: 0 1em;
    list-style: none;
    margin-bottom: 10px;
    padding: 15px 10px 8px;
}
.menu .active,
.menu .current,
.menu .activeparent,
.menu li:hover
{
    color:rgb(255, 255, 255);
    text-decoration: none;
    border-bottom: 1px solid rgb(255, 255, 255);
}


/* ------------------------------------------------ DropDownMenyn / Drop Down Menu -------------------------------------------- */

 .menu .dropdown ul{
    top: calc(100% + 1px);
    left: 26px;
    background: rgba(29, 165, 156, 0.8);
    border-bottom:none;
    border-width: 0px;
}


.menu .dropdown li {
    white-space: nowrap;
    background: none;
}

.menu .dropdown li a {
    min-width: 100px;
    padding: 25px 0px 5px 0px;
    margin-left: 10px;
    margin-right: 10px;
}


/*-------------------------------------------------------------- Moduler / Modul / Länkboxar ------------------------------------------------------------------------*/

.modulbackground {
    background: #FFFFFF;
    padding-top:1vh;
    padding-bottom:1vh;
}

.moduleboxwrapper {
    position: relative;
    overflow: hidden; 
    cursor: pointer; 
    margin:0; 
    width:100%;
}

.moduleboximage {
    height: 55vh;
    position: relative;
    background-size: cover;
    background-position: center top;
    transform: scale(1.1);
    transition: transform 0.5s ease-out 0s;
}

.moduleboximage:hover {
    height: 55vh;
    position: relative;
    background-size: cover;
    background-position: center top;
    transform: scale(1.0);
    transition: transform 0.5s ease-out 0s;
}

a.moduleboximage{
    display:block;
}

.boxtitle1 {
    margin-top:38%;
    font-family:'Roboto', san-serif;
    font-weight: 500;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    line-height: 1.5em;
    position:absolute; 
    height:auto;
    width:100%;
    color: #FFFFFF;
    text-align: center;
    transition: margin-top 0.3s ease-out 0s;
}

.moduleboxwrapper:hover .boxtitle1 {
    margin-top:36%;
    transition: margin-top 0.3s ease-out 0s;
}

.box.boxtitle2 {
    color: #FFFFFF;
    margin-top:42%;
    font-weight: 500;
    font-size: 1.2em;
    letter-spacing: 0px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    line-height: 1.5em;
    position:absolute; 
    height:auto;
    width:100%;
    text-align: center;
    transition: margin-top 0.3s ease-out 0s;
}

.moduleboxwrapper:hover .boxtitle2 {
    margin-top:40%;
    transition: margin-top 0.3s ease-out 0s;
}

.boxtitle3wrapper  {
    margin-top:51%;
    font-family:'Roboto', san-serif;
    font-weight: 500;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    line-height: 1.5em;
    position:absolute; 
    height:auto;
    width:100%;
    color: #FFFFFF;
    text-align: center;
    opacity: 0.0;
    transition: opacity 0.3s ease-out 0s, margin-top 0.3s ease-out 0s;
}

.moduleboxwrapper:hover .boxtitle3wrapper {
    opacity: 1.0;
    margin-top:49%;
    transition: opacity 0.3s ease-out 0s, margin-top 0.3s ease-out 0s;
}

.box.boxtitle3 {
    height:auto;
    max-width:auto;
    border: solid;
    border-width:1px;
    padding:10px 20px 10px 20px;
}

/* --------------------------------------------------------- Kontaktformulär / Kontaktbanner ----------------------------------------- */

.boltforms-row label {
    font-weight: 400;
    margin-bottom: 0;
}

.boltforms-row input {
    width: 100%;
    color: #606060;
}

.boltforms-row textarea {
    width: 100%;
    color: #606060;
    height: 16vh;
}

.boltforms-row button {
    display: block;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0;
    border: none;
    border-radius: 2px;
    height: auto;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.1em;
}


/* --------------------------------------------------------- Nyheter / Nyhet ----------------------------------------- */



.nyheter {
    margin-top:15vh;
}


.nyhet {
    margin-top:15vh;
}
 

.pagination {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    margin-top:21px;
    width:100%;
}

.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

.pagination  li a {
    background: #999999;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #666666;
}


.sharesymbol {
    border-radius: 10px 0px 0px 10px;
    background: #000000;
    border: solid 1px #000000;
}

.sharesymbol img {
    margin:10px 5px 10px 5px;
}

.sharebuttons {
    border-radius: 0px 10px 10px 0px;
    border: solid 1px #000000;
}

.sharebuttons img {
    margin:10px 0px 10px 30px;
}

.nyhet {
margin-bottom:10vh;
       margin-top:5vh; 
}


.bloggdate {
    color:#666;
    margin-top:0px;
    margin-bottom:0px;
    padding-top:0px;
    padding-bottom:0px;
}

.blogg {
    margin-top:15vh;
}


.blogginlagg {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;

}

.bloggpost {
margin-top:90px;
margin-bottom:10vh;

}

.bloggtitelinramning {
   margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.blogginnehall {
    max-width:800px;
    position: relative;
    z-index: 15;
    background: rgb(255, 255, 255);
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    
}

.blogginnehall h1 {
    font-size: 3.7em;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.bloggtitelinramning h2 {
    font-size: 1.8em;
    margin-top: 4vh;
    margin-bottom:1vh;
    padding-top: 0px;
    padding-bottom:0px;
}

.bloggparagraf p, .bloggparagraf2 p {
    margin-top:1vh;
}

.bloggmaxw {
max-width:640px;
}



a.lasmer  {
    font-size:0.8em;
    border: solid;
    border-width: 0px;
    padding: 10px;

    text-decoration: none;
    background: #999999;
    color: #FFFFFF;
}

a.lasmer:hover  {
    text-decoration: none;
}



.gobackdiv {
margin-bottom:5vh;
}
    



/*--------------------- Kontaktbanner-texten ----------------------*/
.kontaktbanner {
    background: rgba(40, 142, 135, 0.8);
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 10px;
}

.socialmedia {
    font-size:1.3rem;
    letter-spacing: 5px;
}

.kontaktbanner a {
    color: #ffffff;
}

.kontaktbanner h5 {
    color: #ffffff;
    font-size: 1.7em;
    margin-top: 18px;
    margin-bottom: 2vh;
}

.kontaktbanner h2 {
    padding: 0px 0 10px;
}

.kontaktbanner p {
    font-size: 1.1em;
    margin-bottom: 0px;
    line-height: 150%;
}

.kontaktbanner .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}

button[type=submit], input[type=submit] {
    background: #FFFFFF;
    color: #000000;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: #FFFFFF;
    color: #000000;
}

/*------------------Give map a height and it's images no max-width----------*/
    .map-canvas {
    height: 38vh;
}

.map-canvas img {
    max-width: none;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*Allt under M-nivå*/
@media (max-width: var(--breakpoint-m)) {
    
    .menu .dropdown ul {
    background: rgba(0, 35, 85, 0.0);
    }
    
    .menu.show {
    background: rgba(29,165,156,.8);
}
    
} /* SLUT - Allt under M-nivå*/ 

/*Allt under S-nivå*/
@media (max-width: var(--breakpoint-s)) {
    
  
}  

/*Allt under XS-nivå*/
@media (max-width: var(--breakpoint-xs)) {
    
    
}

@media screen and (orientation: landscape) and (max-width: 800px) {


}

@media screen and (orientation: landscape) and (max-width: 800px) {

    
}

/*--------------------------Make FB widget responsive------------------------*/
    .fb_iframe_widget,
    .fb_iframe_widget span,
    .fb_iframe_widget iframe[style],
    .twitter-timeline {
        width: 100% !important;
    }
